.pc_container{
  border: 4px solid #ccc;
  padding:0;
  margin-top: 15px;
  margin-bottom: 0;
}
.pc_title{
  font-weight: bold;
  padding: 10px 10px 10px 0;
  width: 100%;
  //color: $orange2;
}
.pc_description {
  margin-top: 10px;
  text-align: left;
  font-size: 10pt;
  line-height: 1.4;
  u {
    text-decoration: none;
  }
}
.addtocartwithoption{
  margin:10px;
}
.pc_products {
  margin: 15px;
  text-align: center;
  position: relative;
  flex: 1;
  border: 1px solid #eaeaea;
  padding: 0 10px 0;
  .price {
    margin-bottom: 5px;
  }
}
.pc_first {
  text-align: left;
  line-height: 1.4;
}
.pc_first, .pc_info {
  position:relative;
  flex:1;
  padding: 15px;
  @include flexbox();
  @include flex-direction(column);
  //@include align-items(center);
  @include justify-content(center);
}
.pc_first {
  padding: 15px 15px 0;
}



#column-left .pc_products{
  width:88%;
}
.pc_products .product-name {
  line-height: 1.2;

}
//.pc_products h4{
//  font-weight:bold;
//  padding:0 10px;
//}
//.pc_products h4 a{
//  color:#2f2f2f;
//}
.pc_products .image img{
  margin:0 auto;
  padding:0 10px;
}
.pc_products .price{
  //  font-size: 14px;
  //  font-weight: bold;
  //  padding-bottom:10px;
  //  margin-bottom:0;
  color: #232f3e;
  font-weight: 700;
  font-size: 10pt;
}
.pc_bundle{
  display:flex;
  justify-content: space-between;
}
.product-name{
  margin:0;
  line-height: 1.4;
}
.product-name a{
  color:#2f2f2f;
  font-size: 10pt;
}
.addtocart i{
  margin-right:5px;
}
.pc_container .pc_plus{
  font-size: 30px;
  font-weight: bold;
  position: relative;
  top: 100px;
  color: #2f2f2f;
}
.pc_container{
  position: relative;
}
.pc_container .pc_info {
  padding:15px;
  text-align:center;
  background-color: #eaeaea;
  //@include align-self(stretch);
  .btn {
    //border: 2px solid #666666;
    color: #ffffff;
  }
}

.image{
  position:relative;
}
.image .product-quantity{
  position:absolute;
  top:0;
  left:0;
}

.product-quantity p{
  background-color:#ee3313;
  color:#fff;
  padding:5px 7px;
  font-size:16px;
  font-weight:bold;
}
.pc_container .pc_info .pc_save{
  font-size:14px;
  font-weight:400;
  margin-bottom:5px;
}
.pc_container .pc_info .pc_save span{
  text-decoration: line-through;
  color: red;
}
.pc_container .pc_info .pc_price{
  //  font-size:18px;
  font-weight:bold;
  margin-bottom:5px;

}
.discount{
  background: red;
  color: #fff;
  width: 30px;
  border-radius: 20px;
  margin-bottom: 0;
  margin-right: 5px;
  position: absolute;
  top: 10px;
  right: 0;
  height: 30px;
  padding-top:5px;
  font-size:11px;
}
.regular-price{
  text-decoration:line-through;
  color:#d7145d;
  font-size:12px;
}
.pc_model{
  background-color:rgba(0,0,0,0.8);
}
.pcmodel{
  position:fixed;
  background-color:rgba(0,0,0,0.8);
  bottom: 0;
  left: 0;
  outline: 0 none;
  right: 0;
  top: 0;
  z-index: 1040;
  overflow-y:scroll;
  overflow-x:hidden;
  display:none;
  -webkit-overflow-scrolling: touch;
}
.pc_model_dialog{
  background-color:#fff;
  position: relative;
  width: 80%;
  left: 10%;
  top: 10vh;
}
.pc_model_dialog_content{
  background-clip: padding-box;
  background-color: #fff;
  outline: 0 none;
  position: relative;
}
.pc_model_dialog_body{
  padding: 15px;
  position: relative;
}
.col_s_6{
  padding:0 15px;
  width:48%;
  float:left;
}
.col_s_12{
  padding:0 15px;
  width:100%;
}
.pc_clear{
  clear:both;
}
.pc_close{
  background-color: #fff;
  color: #000;
  font-size: 20px;
  right: -34px;
  padding: 12px;
  position: absolute;
  cursor:pointer;
  z-index: 1;
  top: 0;
}
.journal_header{
  z-index:2 !important;
}
.journal_footer{
  z-index:2 !important;
}
#column-left .center_plus{
  top:0 !important;
  margin: 0 auto;
  text-align: center;
  left:45%;
}
.journal_content{
  /* z-index:0 !important; */
}
.pc_scroll{
  overflow:hidden;
}



@media (max-width:767px){

  .mob_akcijos_title_bundles {
    //background-color: $orange2;
    color: #fff;
    text-align: center;
    letter-spacing: 2px;
    font-size: 16pt;
    padding: 6px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .pc_bundle{
    display:block;
    height:auto;
    .pc_title, .pc_description {
      font-size: 12pt;
    }
  }
  .pc_container .pc_plus{
    top: 0;
    left:47%;
  }
  .juostos_baterijos .pr-block span:not(.btn) {
    padding: 0 15px !important;
  }
  .pc_container {
    margin-top: 0;
  }
  .pc_bundle {
    //    .pc_mobile_toggle {
    //      margin: 0 10px 10px;
    //    }
    //    > *:not(.pc_first):not(.pc_mobile_toggle):not(.clearfix):not(.pc_info) {
    //      display: none;
    //    }
    .pc_products {
      margin: 10px;
      width: calc(50% - 20px);
      float: left;
      height: 195px;
    }
    .pc_plus {
      clear: both;
      text-align: center;
      line-height: 1;
      top: initial;
      left: initial;
      &.nr1, &.nr3, &.nr5 {
        display: none !important;
      }
    }
  }
  .product-info {
    #column-right {
      .row > .col-sm-12:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }
}
@media (min-width:992px){
  .product-info {
    .pc_bundle {
      .pc_products {
        width: 100px;
        .image {
          img {
          }
        }
      }
    }
  }
  .pc_model_dialog{
    .prod_items {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      .form-group .control-label {
        max-width: initial;
      }
      .col_s_5 {
        max-width: 330px;
      }
    }
  }
  .pc_model_dialog_header {
    text-align: center;
  }

  .pc_first {
//    .akcija {
//      position: absolute;
//      left: -30px;
//      top: -4px;
//      background: $orange2;
//      color: white;
//      font-weight: bold;
//      width: 30px;
//      height: calc(100% + 8px);
//      text-align: center;
//      @include flexbox();
//      @include align-items(center);
//      @include justify-content(center);
//    }
  }
}

.pc_model_dialog_header {
  u {
    text-decoration: none;
  }
}


#column-right {
  .pc_products {
    .image {
      img {
        //        max-width: 150px;
      }
    }
  }
}