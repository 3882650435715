// auto submenu links layout

@media screen and (min-width: 992px) {
  // rekuperatoriai
  .one-column #content .module-categories-359 .category-layout:not(.swiper-slide) {
    width: auto;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.tab-content {
  .table-responsive {
    table {
      tr {
        &:first-child {
          td {
            background-color: #D9D9D9;
            font-weight: bold;
          }
        }
      }
    }
  }
}